import theme from '@/theme'
import React, { CSSProperties } from 'react'

const styles: { [key: string]: CSSProperties } = {
    contentWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    content: {
        width: '100%',
        maxWidth: theme.contentContainer.width,
        fontSize: 14,
        fontWeight: 300,
        color: theme.colors.black,
        fontFamily: theme.typography.fontFamily,
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
        marginBottom: 40,
    },
    list1: {
        listStyleType: 'decimal',
    },
    list2: {
        listStyleType: 'lower-roman',
    },
    list3: {
        listStyleType: 'lower-latin',
    },

    date: {
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    index: {
        fontSize: 14,
        fontWeight: 600,
        marginTop: 30,
    },
    table: {
        margin: '0 auto',
        borderCollapse: 'collapse',
        border: '1px solid #333333',
    },
    tw: {
        fontWeight: 300,
    },
    tableCell: {
        padding: 15,
        border: ' 1px solid rgb(51, 51, 51)',
    },
}

const PrivacyPolicy = () => {
    return (
        <>
            <title>プライバシーポリシー｜ScopeX</title>
            <div style={styles.contentWrapper}>
                <div style={styles.content}>
                    <h2 style={styles.title}>TBM温室効果ガス排出量見える化WEBサービス・プライバシーポリシー</h2>
                    <p>
                        株式会社ＴＢＭ（以下「当社」といいます。）は、当社が提供するアプリケーションサービス「ＴＢＭ温室効果ガス排出量見える化WEBサービス」（以下「本アプリ」といいます。）において、ユーザーに関する情報を以下の通り取り扱います。
                    </p>
                    <div style={styles.index}>第 1 条（総則）</div>
                    <ol style={styles.list1}>
                        <li>
                            <p>
                                当社は、ユーザーの個人情報を安全に保管し、ユーザーの意思を尊重した利用・取扱いを行う環境を築き、ユーザーからの信頼をいただくと共に、その環境の下でユーザーに感動をもたらし、人々の好奇心を刺激するサービスの数々を提供し続けたいと願っています。当社は、かかる方針のもと、以下の通り個人情報の取扱いに関するポリシーを定め、収集したユーザーに関する個人情報の適切な取扱いに取り組みます。
                            </p>
                        </li>
                        <li>
                            <p>
                                ＴＢＭ温室効果ガス排出量見える化WEBサービス・プライバシーポリシー（以下「本ポリシー」といいます。）
                                は、本アプリ（本アプリに付随して当社が提供するサービスを含みます。）
                                の利用に関し適用されます。また、当社が、当社の運営するウェブサイト上に掲載するプライバシーポリシーその他の個人情報保護方針又はＴＢＭ温室効果ガス見える化WEBサービスに関する利用規約等においてユーザー情報の取扱いについて規定する場合、当該規定も適用されるものとし、当該規定等が本ポリシーと抵触する場合には、本ポリシーが優先されるものとします。
                            </p>
                        </li>
                        <li>
                            <p>
                                提携パートナーにより提供される提携サービスその他当社以外の者が提供するサービスについては、本ポリシーの規定は適用されません。これらのサービスにおけるユーザー情報の取扱いについては、当該外部サービスを提供する事業者が別途定めるプライバシーポリシー等をご参照ください。
                            </p>
                        </li>
                    </ol>
                    <div style={styles.index}>第 2 条（取得する情報）</div>
                    <ol style={styles.list1}>
                        <li>
                            <p>
                                当社は、本アプリにおいて、以下に定める通り、個人情報（個人情報保護法第2条第1項により定義された「個人情報」をいい、以下同様とします。）を含むユーザー情報を取得します。
                            </p>
                            <ol style={styles.list2}>
                                <li>
                                    <p>ユーザーにご提供いただく情報</p>
                                    <ol style={styles.list3}>
                                        <li>
                                            <p>
                                                基礎情報：氏名、勤務先名称、勤務先所在地、勤務先電話番号、メールアドレス等
                                                <br />
                                                アカウント登録、ご本人確認、不正利用の防止のため、これらの情報をユーザーにご登録いただきます。
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                その他登録情報：本アプリのサービスの提供のため、基礎情報以外の情報をユーザーにご登録いただくことがあります。
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>本アプリのご利用時に当社が収集する情報</p>
                                    <ol style={styles.list3}>
                                        <li>
                                            <p>
                                                ログ情報及び行動履歴情報
                                                <br />
                                                当社は、本アプリの維持及び改善、又は不正行為防止のため、本アプリの利用時に自動で生成、保存されるIPアドレス、ユーザーからのリクエスト日時、アプリ内での操作履歴の情報を収集することがあります。
                                            </p>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                当社は、ユーザー情報の取得にあたっては、偽りその他不正の手段によらず、適正な方法により取得します。また、当社は、ユーザーが本アプリを利用することによる取得以外の方法でユーザー情報を取得する場合には、その利用目的を事前に通知又は公表します。
                            </p>
                        </li>
                    </ol>
                    <div style={styles.index}>第 3 条（利用目的）</div>
                    <ol style={styles.list1}>
                        <li>
                            <p>
                                当社は、本アプリの利用を通じて取得したユーザー情報を、下記の目的の範囲内で適正に取り扱います。ユーザーご本人の同意なく利用目的の範囲を超えて利用することはありません。
                            </p>
                            <table style={{ ...styles.table, ...styles.tableCell }}>
                                <tbody>
                                    <tr style={{ ...styles.tableCell, textAlign: 'center' }}>
                                        <th style={{ ...styles.tw, ...styles.tableCell, width: 300 }}>利用目的</th>
                                        <th style={{ ...styles.tw, ...styles.tableCell, width: 600 }}>
                                            利用目的の詳細
                                        </th>
                                        <th style={{ ...styles.tw, ...styles.tableCell, width: 300 }}>利用する情報</th>
                                    </tr>
                                    {/* row 1 */}
                                    <tr style={{ ...styles.tableCell }}>
                                        <td style={{ ...styles.tableCell }}>本アプリの提供・維持・改善</td>
                                        <td style={{ ...styles.tableCell }}>
                                            ・本アプリにおけるご本人確認及び不正利用の防止のため
                                            <br />
                                            ・ユーザーへの通知、通信その他のコミュニケーション機能を含む本アプリの円滑な提供、維持及び改善のため
                                            <br />
                                            ・本アプリに関するユーザーへのアンケート等の実施のため
                                        </td>
                                        <td style={{ ...styles.tableCell }}>基礎情報</td>
                                    </tr>

                                    <tr style={{ ...styles.tableCell }}>
                                        <td style={{ ...styles.tableCell }}>ユーザーへの通知・対応等</td>
                                        <td style={{ ...styles.tableCell }}>
                                            ・本アプリに関するご案内、お問い合わせ等への対応のため
                                            <br />
                                            ・本アプリに関連する新たなサービスの通知又は提供のため
                                            <br />
                                            ・本アプリに関する利用規約又は本ポリシーの変更、本アプリの停止・中止・契約解除その他本アプリに関する重要なお知らせ等の通知のため
                                            <br />
                                            ・本アプリの改善、新機能搭載、オプション機能搭載等のご案内のため
                                        </td>
                                        <td style={{ ...styles.tableCell }}>基礎情報</td>
                                    </tr>
                                    <tr style={{ ...styles.tableCell }}>
                                        <td style={{ ...styles.tableCell }}>第三者提供</td>
                                        <td style={{ ...styles.tableCell }}>
                                            右に記載の情報の提供を受けた第三者は、当該情報を以下の目的のために利用します。
                                            <br />
                                            ・本アプリの提供のため
                                            <br />
                                            ・市場分析、マーケティングのため
                                        </td>
                                        <td style={{ ...styles.tableCell }}>
                                            基礎情報
                                            <br />
                                            ログ情報及び行動履歴情報
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>
                            <p>
                                当社は、前項の利用目的を、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には、当社が別途定める方法により、ユーザーに通知又は公表します。
                            </p>
                        </li>
                    </ol>
                    <div style={styles.index}>第 4 条（第三者提供）</div>
                    <ol style={styles.list1}>
                        <li>
                            <p>
                                当社は、ユーザー情報のうち、個人情報については、以下の場合を除き、第三者に提供することはありません。
                            </p>
                            <ol style={styles.list2}>
                                <li>
                                    <p>ユーザーの同意を得た場合</p>
                                </li>
                                <li>
                                    <p>法令に基づく場合</p>
                                </li>
                                <li>
                                    <p>
                                        本アプリ利用上、ユーザーが他人の利益を害し若しくは公序良俗に反する行為その他本アプリの利用規約に違反する行為を行い又はこれを行おうとするときに、当該行為に対して必要な措置をとる場合
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で
                                        あって、本人の同意を得ることにより当該事務の遂行に支障を 及ぼすおそれがあるとき
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        合併、会社分割、事業譲渡その他の事由によりユーザーの個人情報を含む事業の承継がなされる場合
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                当社は、当社が本アプリの開発、改善、メンテナンス等を委託するシステム会社に対し、ユーザーに対して本アプリを提供するため、ユーザーの基礎情報を提供することができるものとします。
                            </p>
                        </li>
                        <li>
                            <p>
                                当社は、本アプリと提携するサービス（以下「提携サービス」といいます。）
                                を提供する事業者（以下「提携パートナー」といいます。）に対し、ユーザーに提携サービスを提供するため、ユーザーの同意を得た上、個人情報を含むユーザー情報を提供することができるものとします。
                            </p>
                        </li>
                    </ol>
                    <div style={styles.index}>第 5 条（個人情報の取扱いの委託）</div>
                    <p>
                        　当社は、ユーザーから取得した個人情報の全部又は一部の取扱いを第三者に委託（個人情報を含む情報の管理を事業者に委託する場合などをいいます。）することがあります。この場合、当社は、当該委託先との間で本ポリシーに準じる内容の秘密保持契約等をあらかじめ締結するとともに、当該委託先において情報の適切な安全管理が図られるよう、必要かつ適切な監督を行います。
                    </p>
                    <div style={styles.index}>第 6 条（共同利用）</div>
                    <p>
                        　当社は、提携パートナーとの間で、提携サービスの提供に必要な範囲において、ユーザーの個人情報を共同利用することがあります。この場合、当社は、あらかじめ、提携パートナーの名称、共同利用目的、共同利用する情報の種類を公表するものとします。
                    </p>
                    <div style={styles.index}>第 7 条（安全管理体制）</div>
                    <ol style={styles.list1}>
                        <li>
                            <p>
                                当社は、ユーザー情報の漏洩、滅失又は毀損の防止その他のユーザー情報の保護のため、個人情報ファイルへのアクセス制限の実施、アクセスログの記録、また外部からの不正アクセス防止のためのセキュリティソフトの導入等、ユーザー情報の安全管理のために必要かつ適切な措置を講じています。
                            </p>
                        </li>
                        <li>
                            <p>
                                当社は、代表取締役をユーザー情報管理責任者とし、ユーザー情報の適正な管理及び継続的な改善を実施します。
                            </p>
                        </li>
                    </ol>
                    <div style={styles.index}>第 8 条（ユーザーの情報開示・訂正等の権利）</div>
                    <ol style={styles.list1}>
                        <li>
                            <p>
                                ユーザーは、ユーザーが当社に提供した個人情報について、以下に定める手続に従い、開示を請求することができます。
                            </p>
                            <ol style={styles.list2}>
                                <li>
                                    <p>
                                        当社ウェブサイト上の所定のフォームに入力し送信する方法、又は申請書に本人確認（代理人による申請の場合、適正な代理人
                                        であることの確認）のために必要な書類を同封のうえ、郵送する方法によりご請求ください。書面による申請の場合は、1
                                        回の申請ごとに、手数料として 1000 円を申し受けます。
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        前号に基づく申出につき本人確認がなされたときは、当社は、合理的な範囲で個人情報の開示をします。ただし、個人情報保護
                                        法その他の法令により当社が開示義務を負わない場合又は正当な理由なく同内容の請求が何度も繰り返される場合はこの限り
                                        ではありません。
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                当社の保有するユーザーに関する個人情報の内容が事実と異なる場合、ユーザーは、以下に定める手続に従い、訂正、追加、削除を請求することができます。
                            </p>
                            <ol style={styles.list2}>
                                <li>
                                    <p>
                                        当社ウェブサイト上の所定のフォームに入力し送信する方法、又は申請書に本人確認（代理人による申請の場合、適正な代理人
                                        であることの確認）のために必要な書類を同封のうえ、郵送する方法によりご請求ください。
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        前号に基づく申出につき本人確認がなされたときは、当社は、合理的な範囲で遅滞なく調査を行い、その結果に基づき個人情報の訂正、追加、削除をします。ただし、個人情報保護法その他の法令により当社がこれらの義務を負わない場合、正当な理由なく同内容の請求が何度も繰り返される場合、又は過度な技術的作業を要する場合はこの限りではありません。
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <div style={styles.index}>第 9 条（本ポリシーの変更）</div>
                    <ol style={styles.list1}>
                        <li>
                            <p>
                                当社は、ユーザー情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、随時本ポリシーを変更することがあります。
                            </p>
                        </li>
                        <li>
                            <p>
                                変更後の本ポリシーについては、本アプリ上又は当社の運営するウェブサイトでの掲示その他分かりやすい方法により告知します。ただし、法令上ユーザーの同意が必要となるような内容の変更を行うときは、別途当社が定める方法により、ユーザーの同意を取得します。
                            </p>
                        </li>
                    </ol>
                    <div style={styles.index}>第 10 条（お問い合わせ）</div>
                    <p>
                        当社のユーザー情報の取扱いに関するご意見、ご質問、苦情のお申出その他ユーザー情報の取扱いに関するお問い合わせは、下記窓口までご連絡ください。
                    </p>
                    <p>
                        個人情報保護方針及び個人情報に関するお問い合わせ窓口
                        <br />
                        株式会社ＴＢＭ　個人情報保護担当
                        <br />
                        メールアドレス：tbmprivacy@tb-m.com
                        <br />
                    </p>
                    <p style={styles.date}>2021 年 11 月 4 日制定</p>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy
